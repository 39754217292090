@font-face {
  font-family: 'MiFuente';
  src: url('./fonts/FSSinclair.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'MiFuente';
  src: url('./fonts/FSSinclair-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

body {
    font-family: 'MiFuente', sans-serif;
}

/* Sección general para diapositivas */
.App-section {
  width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  text-align: center;
  box-sizing: border-box;
  padding: 20px;
}

/* Sección de la diapositiva de imagen */
.image-slide {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
}

/* Imagen responsiva */
.responsive-image {
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}

/* Sección de la diapositiva de 4LogicONE */
.logicone-slide {
  font-family: 'MiFuente', sans-serif;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: #f5f5f5;
  padding: 20px;
}

/* Contenedor del contenido */
.logicone-content {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}

/* Título grande 4LogicONE */
.logicone-title {
  font-size: 3.5rem;
  font-weight: bold;
  color: black;
  margin-bottom: 10px;
}

/* Subtítulo más pequeño */
.logicone-subtitle {
  font-size: 1.5rem;
  color: black;
  margin-bottom: 15px;
}

/* Texto más pequeño */
.logicone-text {
  font-size: 1.2rem;
  color: #333;
  margin-bottom: 30px;
}

/* Contenedor para la imagen */
.image-container {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

/* Imagen del móvil */
.logicone-image {
  max-width: 90%;
  height: auto;
}
/* Sección Visión y Misión */
.vision-mission {
  font-family: 'MiFuente', sans-serif;
  background-image: url('./assets/bg2.jpg'); /* Ruta de la imagen de fondo */
  background-size: cover; /* Asegura que la imagen cubra la sección */
  background-position: center; /* Centra la imagen en la sección */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  color: white; /* Color del texto en blanco para resaltar sobre el fondo */
  padding: 100px 20px; /* Mayor margen superior e inferior para que la sección sea más alta */
  text-align: center; /* Centra el contenido dentro de la sección */
  box-sizing: border-box; /* Incluye padding y borde en el cálculo del ancho y alto */
  min-height: 100vh; /* Asegura que la sección ocupe al menos el alto de la ventana */
}

/* Título de la sección */
.vision-mission-title {
  font-size: 4rem; /* Tamaño grande para el título */
  margin-bottom: 30px; /* Espacio debajo del título */
  color: white; /* Color del texto en blanco */
}

/* Párrafo de la sección */
.vision-mission-text {
  font-size: 1.5rem; /* Tamaño del texto mayor para mayor legibilidad */
  line-height: 1.8; /* Espacio entre líneas */
  max-width: 800px; /* Limita el ancho del párrafo */
  margin: 0 auto; /* Centra el párrafo */
  color: white; /* Color del texto en blanco */
}

/* Contenedor de contenido */
.vision-mission-content {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centra el contenido dentro del contenedor */
}
/* Sección general de diapositivas */
.informative-slides {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  color: black;
  box-sizing: border-box;
}

/* Estilo para cada diapositiva */
.slide {
  font-family: 'MiFuente', sans-serif;
  width: 100%;
  padding: 40px 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

/* Título (h3) de cada diapositiva */
.slide h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 10px;
}

/* Párrafo de cada diapositiva */
.slide p {
  font-size: 1.2rem;
  max-width: 800px;
  margin-bottom: 20px;
  text-align: center;
}

/* Imagen de cada diapositiva */
.slide-image {
  max-width: 50%;
  height: auto;
  margin-top: 20px;
}

/* Ajustes para pantallas pequeñas */
@media (max-width: 768px) {
  .slide h3 {
    font-size: 1.5rem;
  }

  .slide p {
    font-size: 1rem;
    max-width: 100%;
    padding: 0 20px;
  }

  .slide-image {
    max-width: 90%;
  }
}

/* Estilos para la sección de contacto */
.contact-section {
  width: 100%;
  padding: 40px 20px;
  background-color: #f5f5f5;
  box-sizing: border-box;
  text-align: center;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 20px;
}

.contact-info {
  font-size: 1.2rem;
  margin-bottom: 20px;
}

.contact-info a {
  color: orange;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.contact-photo {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}

.contact-buttons {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.contact-button {
  font-size: 1rem;
  color: orange;
  text-decoration: none;
  border: 1px solid orange;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.contact-button:hover {
  background-color: orange;
  color: white;
}

/* Media queries para diseño responsive */
@media (max-width: 768px) {
  .contact-title {
    font-size: 2rem;
  }

  .contact-info {
    font-size: 1rem;
  }

  .contact-buttons {
    flex-direction: column;
    gap: 10px;
  }

  .contact-button {
    font-size: 0.9rem;
    padding: 8px 16px;
  }
}

@media (max-width: 480px) {
  .contact-title {
    font-size: 1.5rem;
  }

  .contact-info {
    font-size: 0.9rem;
  }

  .contact-button {
    font-size: 0.8rem;
    padding: 6px 12px;
  }
}

/* Sección de Casos de Éxito */
.success-cases {
  width: 100%;
  padding: 40px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  color: black;
  background-color: #f8f8f8;
}

.success-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  text-align: center;
}

.case {
  width: 100%;
  max-width: 800px;
  margin-bottom: 40px;
  text-align: center;
}

.case p {
  font-size: 1.2rem;
  line-height: 1.6;
  margin-bottom: 20px;
}

.case-image {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

/* Media queries para diseño responsive */
@media (max-width: 768px) {
  .slide h3 {
    font-size: 1.5rem;
  }

  .slide p {
    font-size: 1rem;
  }

  .success-title {
    font-size: 2rem;
  }

  .case p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .slide h3 {
    font-size: 1.2rem;
  }

  .slide p {
    font-size: 0.9rem;
  }

  .success-title {
    font-size: 1.5rem;
  }

  .case p {
    font-size: 0.9rem;
  }
}

/* Sección Misión */
.mision {
  font-family: 'MiFuente', sans-serif;
  background-color: white; /* Fondo blanco para esta sección */
  color: black; /* Color de texto negro */
  padding: 40px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* Título de la Misión */
.mision-title {
  font-size: 3rem;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
}

/* Texto de la Misión */
.mision-text {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

/* Contenedor de columnas */
.columns {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  margin: 40px 0;
}

/* Columna izquierda y derecha */
.column-left, .column-right {
  width: 48%; /* Ajusta el ancho de las columnas */
}

/* Títulos de las columnas */
.column-left h4, .column-right h4 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

/* Texto dentro de las columnas */
.column-left p, .column-right p {
  font-size: 1rem;
  line-height: 1.6;
}

/* Contenedor de palabras y logo */
.vision-mission-words {
  display: flex;
  justify-content: center; /* Centra las columnas horizontalmente */
  align-items: flex-start;
  width: 100%;
  max-width: 1200px;
  margin-top: 40px;
}

/* Columnas dentro de la sección de palabras */
.words-column {
  flex: 1;
  text-align: center;
  margin: 0 10px; /* Menor margen entre las columnas */
}

/* Texto en las columnas */
.words-column span {
  display: block;
  font-size: 1rem;
  margin: 5px 0; /* Menor espacio entre palabras */
  text-transform: uppercase; /* Convertir texto a mayúsculas */
}

/* Logo en el centro */
.words-center {
  flex: 2;
  text-align: center;
}

/* Logo */
.logo {
  width: 150px;
  height: auto;
}

/* Subtítulo de la sección */
.App-subtitle {
  font-size: 2rem;
  margin-top: 10px;
}



/* Resaltar el texto en naranja */
.text-orange {
  color: orange;
}
/* Resaltar el texto en naranja */
.text-black {
  color: black;
}

/* Resaltar el texto en gris */
.text-gray {
  color: gray;
}

/* Media queries para diseño responsive */
@media (max-width: 768px) {
  .mision-title {
    font-size: 2.5rem; /* Tamaño más pequeño en pantallas más pequeñas */
  }

  .mision-text {
    font-size: 1rem;
  }

  .columns {
    flex-direction: column;
  }

  .column-left, .column-right {
    width: 100%;
    margin-bottom: 20px;
  }

  .vision-mission-words {
    flex-direction: column;
    align-items: center;
  }

  .words-column {
    margin-bottom: 20px;
  }

  .words-center {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .mision-title {
    font-size: 2rem; /* Tamaño aún más pequeño */
  }

  .mision-text {
    font-size: 0.9rem;
  }

  .words-column span {
    font-size: 0.9rem;
  }
}

/* Estilos generales de la aplicación */
.App {
  font-family: 'MiFuente', sans-serif;
  text-align: center;
}

.App-navbar {
  font-family: 'MiFuente', sans-serif;
  position: sticky;
  top: 0;
  background-color: #282c34;
  padding: 10px;
  color: white;
  z-index: 1000;
  display: flex;
  justify-content: space-between; /* Alinear logo a la izquierda y links a la derecha */
  align-items: center;
}

.App-navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.App-navbar li {
  margin: 0 15px;
}

.App-navbar a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
}

/* Estilos para el menú hamburguesa */
.hamburger {
  display: none; /* Ocultar por defecto */
  flex-direction: column;
  cursor: pointer;
}

.line {
  height: 4px;
  width: 25px;
  background-color: white;
  margin: 3px 0;
}

/* Media queries para pantallas pequeñas */
@media (max-width: 768px) {
  .navbar-links {
    display: none; /* Ocultar los enlaces por defecto en móviles */
    flex-direction: column; /* Colocar enlaces verticalmente */
    position: absolute;
    top: 60px; /* Justo debajo del navbar */
    right: 0;
    background-color: #282c34; /* Color de fondo del menú */
    width: 100%; /* Asegurar que ocupe todo el ancho */
  }

  .navbar-links.active {
    display: flex; /* Mostrar enlaces cuando se activa el menú hamburguesa */
  }

  .hamburger {
    display: flex; /* Mostrar el menú hamburguesa en pantallas pequeñas */
  }

  .App-navbar ul {
    flex-direction: column; /* Alinear los enlaces verticalmente */
    padding: 10px 0;
  }

  .App-navbar li {
    margin: 10px 0; /* Espaciado vertical entre enlaces */
  }
}

.App-navbar a:hover {
  text-decoration: underline;
}

.logo-container {
  display: flex;
  align-items: center;
}

.navbar-logo {
  height: 40px; /* Ajustar el tamaño del logo */
  margin-right: 10px; /* Espacio entre logo y texto */
}

.navbar-links {
  display: flex;
  justify-content: flex-end;
  flex-grow: 1; /* Hace que los enlaces se alineen a la derecha */
}

.navbar-links li {
  margin-left: 30px; /* Espacio entre los enlaces */
}

.App-main {
  padding: 0px;
}

/* Ajustes generales para la sección .welcome */
.welcome {
    background: none;
    color: black;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100vh; /* Ajustar a 80vh en lugar de 100vh */
    width: 100%;
    position: relative;
    text-align: center;
    padding: 0;
    margin: 0;
    overflow: hidden;
    box-sizing: border-box;
}

@media (max-width: 768px) {
    .welcome {
        height: 50vh; /* Cambiar altura para pantallas pequeñas */
    }
}

@media (max-width: 480px) {
    .welcome {
        height: 40vh; /* Cambiar altura para pantallas muy pequeñas */
    }
}

/* Contenedor que agrupa el texto y el logo */
.title-container {
    font-family: 'MiFuente', sans-serif;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 20px;
    text-align: center;
    flex-wrap: nowrap; /* Impide que los elementos se muevan a una nueva línea */
}

/* Título principal */
.App-title {
    font-size: 4rem;
    margin: 0;
    display: flex;
}

/* Logo */
.App-logo {
  width: 100px;
  height: auto;
}

/* Título secundario */
h3 {
  font-family: 'MiFuente', sans-serif;
  font-size: 2rem;
  margin-top: 20px;
}

/* Media queries para diseño responsive */
@media (max-width: 768px) {
  .title-container {
    flex-direction: column;
  }

  .App-title {
    font-size: 2.5rem;
  }

  .App-logo {
    width: 50px;
  }

  h3 {
    font-size: 1.5rem;
  }
}

@media (max-width: 480px) {
  .App-title {
    font-size: 1.5rem;
  }

  .App-logo {
    width: 50px;
  }

  h3 {
    font-size: 1.2rem;
  }
}

.contact-info {
  margin-bottom: 20px; /* Espaciado inferior */
}

.contact-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 3 columnas en pantallas grandes */
  gap: 10px; /* Espaciado entre elementos */
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columnas en pantallas medianas */
  }
}

@media (max-width: 480px) {
  .contact-grid {
    grid-template-columns: repeat(1, 1fr); /* 1 columna en pantallas pequeñas */
  }
}

.social-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 20px;
  justify-content: center; /* Centra los elementos cuando hay menos de 4 */
}

.contact-button {
  font-size: 1rem;
  color: orange;
  text-decoration: none;
  border: 1px solid orange;
  padding: 10px 20px;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  text-align: center;
}

.contact-button:hover {
  background-color: orange;
  color: white;
}

.video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw; /* Usar vw para ocupar el ancho completo de la ventana */
    height: 100%; /* Usar vh para ocupar el alto completo de la ventana */
    object-fit: cover; /* Ajuste para que cubra el contenedor manteniendo proporción */
    object-position: center 15%;
}

.overlay {
    position: absolute;
    top: 25%; /* Posiciona el contenido al 25% desde la parte superior */
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1; /* Asegura que esté por encima del video */
    transform: translateY(-52%); /* Para ajustar el centro del contenido */
}

@media (max-width: 768px) {
    .video-background {
        height: 100%; /* Ajustar la altura para pantallas medianas */
    }
    .overlay {
        transform: translateY(-70%); /* Para ajustar el centro del contenido */
    }
}

@media (max-width: 480px) {
    .video-background {
        height: 100%; /* Ajustar la altura para pantallas pequeñas */
    }
    .overlay {
        transform: translateY(-73%); /* Para ajustar el centro del contenido */
    }
}

/* Contenedor del contenido superpuesto al video */
.welcome-content {
    display: flex;
    align-items: center;
    gap: 10px; /* Espacio entre el título y el logo */
    text-align: center;
    color: white; /* Para que el texto sea visible sobre el video */
    flex-wrap: nowrap; /* Impide que los elementos se muevan a una nueva línea */
}


