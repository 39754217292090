.App-navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #282c34;
    padding: 10px;
    color: white;
    position: sticky;
    top: 0;
    z-index: 1000;
  }
  
  .logo-container {
    display: flex; 
    align-items: center; 
  }
  
  .logo-link {
    display: flex; 
    align-items: center; 
    text-decoration: none; 
    color: inherit; 
  }
  
  .navbar-logo {
    height: 40px; 
    margin-right: 10px; 
  }
  
  .navbar-links {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;
  }
  
  .navbar-links ul {
    list-style-type: none;
    display: flex;
    margin: 0;
    padding: 0;
  }
  
  .navbar-links li {
    margin: 0 15px;
    display: flex; 
    align-items: center; 
}

.navbar-links a {
    display: flex; 
    align-items: center; 
    color: white; /* Asegúrate de que el color sea blanco */
    text-decoration: none; 
    margin-right: 10px; /* Espaciado entre iconos */
    margin-bottom: 5px; /* Espacio inferior para "respirar" */
}

.navbar-links i {
    margin-right: 5px; 
}

  
  .navbar-links a:hover {
    text-decoration: underline;
  }
  
  .menu-icon {
    display: none;
    cursor: pointer;
    font-size: 1.5rem;
  }
  
  @media (max-width: 768px) {
    .navbar-links {
      display: none; 
      flex-direction: column; 
      position: absolute;
      top: 60px; 
      right: 0;
      background-color: #282c34;
      width: 100%;
      z-index: 1000;
    }
  
    .navbar-links.open {
      display: flex; 
    }
  
    .menu-icon {
      display: block; 
    }
  }
  